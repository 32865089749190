'use client'

import { type Dispatch, type SetStateAction, useState } from 'react'

import { NavigationContext } from '@features/navigation.hooks'

export interface NavigationProviderProps {
  /**
   * Children of the provider.
   */
  children: React.ReactNode
}

export type NavigationContextState = [
  boolean,
  Dispatch<SetStateAction<boolean>>,
]

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
}) => {
  const state = useState(false)

  return (
    <NavigationContext.Provider value={state}>
      {children}
    </NavigationContext.Provider>
  )
}
