import { useState, useLayoutEffect } from 'react'

import { calculateOffset, calculateSections } from '@shared/utils'

import { useNavigation } from '../useNavigation/useNavigation'

import type { NavigationItemElement } from '@features/navigation.components/Navigation/types'

export const useScrollNavigation = (contentWrapperId: string) => {
  const [navItems, setNavItems] = useState<NavigationItemElement[] | null>(null)
  const [currentItem, setCurrentItem] = useState<NavigationItemElement | null>(
    null,
  )
  const [showNavigation] = useNavigation()

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (navItems) {
        // Calculate which section is active
        let currentItem = null
        for (let i = 0; i <= navItems.length - 1; i++) {
          if (navItems[i].position < window.scrollY + 10) {
            currentItem = navItems[i]
          }
        }
        setCurrentItem(currentItem ?? navItems[0] ?? null)
      }
    }

    const handleLayoutShift = () => {
      const newNavItems = calculateSections(calculateOffset(contentWrapperId))
      setNavItems(newNavItems)
      handleScroll()
    }

    const listenToLayoutShift = () => {
      let currentHeight = document.body.clientHeight
      const interval = setInterval(() => {
        if (currentHeight !== document.body.clientHeight) {
          currentHeight = document.body.clientHeight
          handleLayoutShift()
        }
      }, 200)

      return () => clearInterval(interval)
    }

    const cancelLayoutShiftListener = listenToLayoutShift()

    window.addEventListener('scroll', handleScroll)

    if (!navItems) handleLayoutShift()

    return () => {
      cancelLayoutShiftListener()
      window.removeEventListener('scroll', handleScroll)
    }
  }, [contentWrapperId, currentItem, navItems, showNavigation])

  return { currentItem }
}
